
import Icon from '@/components/Icon.vue';
import KycNotEligibleBanner from '@/components/KycNotEligibleBanner.vue'
import constants from '@/utils/constants';
import store from '@/store'

interface ILink {
  title: string;
  link: string;
  icon: string;
}

const LINKS: ILink[] = [
  {
    title: 'Pitch Deck',
    link: '/GoodCrypto_PitchDeck.pdf',
    icon: 'doc',
  },
  {
    title: 'Whitepaper (coming soon)',
    link: '',
    icon: 'note',
  }
];

export default {
  name: 'TokenLaunch',
  components: { Icon, KycNotEligibleBanner },
  computed: {
    LINKS(): ILink[] {
      return LINKS;
    },
    toSaleRoute(): string {
      return constants.NAV_ROUTES[constants.NAV.SEED_ROUND].PATH;
    },
    CONSTS (): Record<string, unknown> {
      return constants
    },
    isNotEligible (): boolean {
      return store.getters['user/currentState'] === constants.USER_STATES.KYC_NOT_ELIGIBLE
    }
  }
};
